/**
 * Class containing the amount of tiles that a user has to buy as well as the size of the package that he can buy for these tiles
 */
class AmountOfTilesToBuy {
  /**
   * @param {Number} exactAmount exact amount of tiles the user needs to buy (without any consideration for the amount of tiles in a pack)
   * @param {Number} amountOfTilesPerPackage the number of tiles containined in a package
   * @param {Number} orderAmount amount of tiles that the user will have to buy (by taking into consideration the amount of tiles in a pack)
   */
  constructor(exactAmount, amountOfTilesPerPackage, orderAmount) {
    this.exactAmount = exactAmount;
    this.amountOfTilesPerPackage = amountOfTilesPerPackage;
    this.orderAmount = orderAmount;
  }
}

export default AmountOfTilesToBuy;
