import React from "react";
import PropTypes from "prop-types";

// Classes
import Wall from "classes/Wall";
import AmountOfTilesToBuy from "classes/AmountOfTilesToBuy";
import UnitOfLengths from "classes/UnitOfLengths";

// Components
import { Document, Font } from "@react-pdf/renderer";
import PdfTileColorVariationsGrid from "components/pdf/PdfTileColorVariationsGrid";
import PdfAmountOfTileCalculations from "components/pdf/PdfAmountOfTileCalculations";

// Helper
import localizer from "localization/localizer";
import { capitalize } from "helpers/StringHelper";

// Styling
import styled from "@react-pdf/styled-components";
import { convert } from "helpers/WallHelpers";
import {
  StyledPage,
  Label,
  Divider,
  NormalText,
  StyledLink,
} from "./pdf/pdfCommonStyles";

// Fonts
import BemboStd from "resources/fonts/BemboStd.ttf";
import BemboStdItalic from "resources/fonts/BemboStd-Italic.ttf";
import BemboStdSemiBold from "resources/fonts/BemboStd-Semibold.ttf";
import BemboStdBold from "resources/fonts/BemboStd-Bold.ttf";
import { formatNumberArteStyle } from "helpers/NumberFormattingHelper";

// Pictures
// This logo is used instead of "arte_logo.png" because there is an issue when using "arte_logo.png" with @react-pdf.
// The issue is that the logo gets rendered twice. This must be linked to the way the PNG was created, but we failed to find the exact reason.
// So for now, use this logo that we have created internally.
import arteLogo from "resources/logos/arte-logo-copyright.png";

const moreInfoLinkBase =
  "https://www.arte-international.com/en/collections/samal";
const createCompleteMoreInfoLink = (name, code) => {
  return moreInfoLinkBase + "/" + name.toLowerCase() + "/" + code;
};

// This the link to the development version of the configurator, needs to be changed
// Ticket: https://trello.com/c/sRZiJ8AD/80-correct-configurator-link-in-pdf
const configuratorLink = "https://samal.arte-international.com/";
const arteWebsiteUrl = "www.arte-international.com";

// Import fonts
// Use .ttf files becaused we've had issues in Chrome when using .otf files before.
// The issue is not well documented in @react-pdf, but you can see a thread here: https://github.com/diegomura/react-pdf/issues/680
Font.register({
  family: "BemboStd",
  fonts: [
    {
      src: BemboStd,
    },
    {
      src: BemboStdBold,
      fontWeight: "bold",
    },
    {
      src: BemboStdSemiBold,
      fontWeight: "semibold",
    },
    {
      src: BemboStdItalic,
      fontStyle: "italic",
    },
  ],
});

// Disable hyphenation since we don't want words breaking over multiple lines
Font.registerHyphenationCallback((word) => [word]);

// Common styling

export const SectionTitle = styled.Text`
  font-size: 12pt;
  font-weight: bold;

  margin-bottom: 8px;
`;

// Tile Section Styling

const TileSection = styled.View`
  width: 100%;
  height: 27%;

  padding: 0 3%;

  flex: 0 0 auto;

  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;
`;

const TileDescriptionSection = styled.View`
  width: 41%;

  display: flex;
  flex-direction: column;
  align-items: center;
`;

const TileImageLayout = styled.View`
  width: 40%;
  height: 100%;

  display: flex;
  flex-direction: column;

  align-items: center;
  justify-content: center;
`;

// The image is at least 50% of the container, but can grow if the text under is not too big
// The max-height is needed because the tiles have different image ratios, so in cases where the image is quite wide, the flex box will give it a lot height,
// but the image will decide to only use a small portion of that height, which will make the text look more distant. We can minimize this problem by limiting the height that it can take up.
const TileImage = styled.Image`
  flex: 1 0;
  flex-basis: 25%;
  max-height: 80%;

  object-fit: contain;
`;

const TileName = styled.Text`
  font-size: 36px;
  font-style: italic;
  text-transform: uppercase;
`;

const CollectionTitleLayout = styled.View`
  display: flex;
  flex-direction: row;
  align-items: end;
`;

const TileDimensionSection = styled.View``;

const CollectionSectionDivider = styled(Divider)`
  margin-top: 4;
`;

const CollectionName = styled.Text`
  font-size: 14px;
  font-style: italic;
`;

const CollectionDescription = styled(NormalText)`
  text-align: justify;
`;

const TileReference = styled(NormalText)`
  margin: 8px 0;
`;

// Calculation section Styling

const CalculationSection = styled.View`
  width: 100%;
  flex: 0 0 auto;
`;

const AmountOfTileCalculations = styled(PdfAmountOfTileCalculations)`
  margin-top: 8px;
  margin-bottom: 16px;
`;

// Visualisation Section Styling

// This image is the only element in the page that resizes if there isn't enough room
// This means if there are more windows and doors to show on the page, this image will be smaller.
const WallRenderedImage = styled.Image`
  min-height: 25%;
  flex-basis: 25%;
  flex: 1 1 auto;
  object-fit: contain;
`;

// Technical Plan Section Styling

const TechnicalHorizontal = styled.View`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  flex-basis: 10%;
  flex-grow: 1;
`;

const TechnicalVertical = styled.View`
  height: 30%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 8px;
`;

const TechnicalPlanImage = styled.Image`
  height: 100%;

  object-fit: contain;
  border-color: black;
  border-width: 1;
`;

const ArtPieceDimensionsText = styled.Text`
  margin-top: 4px;

  display: flex;
  flex-flow: row;
  align-items: center;
`;

const HeightIndicator = styled(NormalText)`
  margin-right: 10px;
  margin-bottom: 10px;
`;

const WidthIndicator = styled(NormalText)`
  margin-top: 10px;
`;

// Color Variations Section Styling

// The other sections of the page have a fixed height, so we let the color variations fill the rest of the height
const ColorVariationsSection = styled.View`
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
`;

const ColorVariationsGrid = styled(PdfTileColorVariationsGrid)`
  align-self: center;
  flex-grow: 1;
  flex-basis: 20%;
`;

const TextLayout = styled.View`
  display: flex;
  flex-direction: row;

  margin-top: 8px;

  font-size: 10px;
`;

const InformationText = styled.Text`
  width: 100%;
  text-align: center;
`;

const MoreInformationLink = styled.Text`
  width: 100%;
  text-align: center;
`;

const BackToConfiguratorLink = styled.Text`
  width: 100%;
  padding-left: 8px;
  text-align: center;
`;

// Footer Styling

const Footer = styled.View`
  width: 100%;
  height: 6%;

  flex: 0 0 auto;

  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
`;

const LogoLayout = styled.View`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const Logo = styled.Image`
  width: 12%;
  margin: 8px 0;
`;

const LogoSubtitle = styled(NormalText)`
  font-size: 6;
  font-weight: semibold;
`;

const Description = styled.View`
  display: flex;
  flex-flow: row;
  align-items: center;
  font-size: 18px;
  cursor: pointer;
`;

/**
 * PDF document with the description of all the choices the user made in the configuration
 * Uses @react-pdf package.
 * @param {{tile: {name: String, tileColorVariants: Object}, colorVariant: {code: Number, name: String, url: String}, wall: Wall}}
 */
const PdfUserChoices = ({
  tile,
  currentPieceOfArt,
  colorVariant,
  wall,
  amountOfTilesToBuy,
  wallRenderedImageUrl,
  wallTechnicalPlanImageUrl,
}) => {
  const moreInfoLink = createCompleteMoreInfoLink(tile.name, colorVariant.code);
  return (
    <Document>
      <StyledPage size="A4">
        <TileSection>
          <TileImageLayout>
            <TileImage src={colorVariant.url} />
          </TileImageLayout>
          <TileDescriptionSection>
            <Label>{localizer.pattern}</Label>
            <TileName>{tile.name}</TileName>
            <CollectionTitleLayout>
              <Label>{localizer.collection}</Label>
              <CollectionName>Samal</CollectionName>
            </CollectionTitleLayout>
            <CollectionSectionDivider />
            <CollectionDescription>
              {localizer.pdf.samalCollectionDescription}
            </CollectionDescription>
            <TileReference>{`${capitalize(localizer.reference)}: ${
              colorVariant.code
            } • ${colorVariant.name}`}</TileReference>
          </TileDescriptionSection>
        </TileSection>
        <Divider />
        <CalculationSection>
          <AmountOfTileCalculations
            wall={wall}
            amountOfTilesToBuy={amountOfTilesToBuy}
          />
        </CalculationSection>
        <Divider />
        <SectionTitle>{capitalize(localizer.visualisation)}</SectionTitle>
        <WallRenderedImage
          src={
            wallRenderedImageUrl ||
            "resources/pageSpecific/visualizer/foreground.png"
          }
        />
        <Divider />
        <Footer>
          <LogoLayout>
            <Logo src={arteLogo} />
            <LogoSubtitle>{localizer.pdf.arteMarketingPhrase}</LogoSubtitle>
          </LogoLayout>
        </Footer>
      </StyledPage>
      <StyledPage size="A4">
        <SectionTitle>{localizer.pdf.technicalPlanSectionTitle}</SectionTitle>
        <TechnicalVertical isArtPiece={currentPieceOfArt}>
          <TechnicalHorizontal>
            <HeightIndicator>
              {`${formatNumberArteStyle(wall.height, wall.unitOfLength)}`}
            </HeightIndicator>
            <TechnicalPlanImage
              src={
                wallTechnicalPlanImageUrl ||
                "resources/tiles/prisma/Samal_Prisma_wall.png"
              }
            />
          </TechnicalHorizontal>
          <WidthIndicator>
            {`${formatNumberArteStyle(wall.width, wall.unitOfLength)}`}
          </WidthIndicator>
          {currentPieceOfArt && (
            <ArtPieceDimensionsText>
              <NormalText>{`${localizer.moduleWH} `}</NormalText>
              <NormalText>
                {`${
                  currentPieceOfArt.dimensions.width
                } x ${formatNumberArteStyle(
                  currentPieceOfArt.dimensions.height,
                  currentPieceOfArt.dimensions.unitOfLength
                )} (${formatNumberArteStyle(
                  convert(
                    currentPieceOfArt.dimensions.width,
                    currentPieceOfArt.dimensions.unitOfLength,
                    UnitOfLengths.feet
                  ),
                  UnitOfLengths.feet
                )} x ${formatNumberArteStyle(
                  convert(
                    currentPieceOfArt.dimensions.height,
                    currentPieceOfArt.dimensions.unitOfLength,
                    UnitOfLengths.feet
                  ),
                  UnitOfLengths.feet
                )})`}
              </NormalText>
            </ArtPieceDimensionsText>
          )}
          {currentPieceOfArt?.extraDescription && (
            <Description>
              <NormalText>
                {localizer[currentPieceOfArt.extraDescription]}
              </NormalText>
            </Description>
          )}
        </TechnicalVertical>
        <Divider />
        <ColorVariationsSection>
          <SectionTitle>{`${localizer.pdf.colorVariantsSectionTitle} (${tile.tileColorVariants.length})`}</SectionTitle>
          <ColorVariationsGrid
            colorVariations={tile.tileColorVariants}
            amountPerRow={tile.tileColorVariants.length > 4 ? 3 : 2}
          />
          <TextLayout>
            <BackToConfiguratorLink>
              {localizer.formatString(
                localizer.pdf.backToConfiguration,
                <StyledLink src={configuratorLink}>
                  {localizer.configurator}
                </StyledLink>
              )}
            </BackToConfiguratorLink>
          </TextLayout>
        </ColorVariationsSection>
        <Divider />
        <TileDimensionSection>
          <SectionTitle>{localizer.pdf.dimensionsSectionTitle}</SectionTitle>
          <TileImage src={tile.tileDimensionsImage} />
          <TextLayout>
            <InformationText>{localizer.pdf.useSpecialGlue}</InformationText>
          </TextLayout>
          <TextLayout>
            <InformationText>{localizer.pdf.glueCoverage}</InformationText>
          </TextLayout>
          <TextLayout>
            <MoreInformationLink>
              {localizer.formatString(
                localizer.pdf.clickHereForMoreInfo,
                <StyledLink src={moreInfoLink}>{localizer.here}</StyledLink>
              )}
            </MoreInformationLink>
          </TextLayout>
        </TileDimensionSection>
        <Divider />
        <Footer>
          <LogoLayout>
            <Logo src={arteLogo} />
            <LogoSubtitle>{arteWebsiteUrl}</LogoSubtitle>
          </LogoLayout>
        </Footer>
      </StyledPage>
    </Document>
  );
};

PdfUserChoices.propTypes = {
  /** Tile picked by the user. Contains all the information related to that tile. */
  tile: PropTypes.shape({
    name: PropTypes.string.isRequired,
    tileColorVariants: PropTypes.arrayOf(
      PropTypes.shape({
        code: PropTypes.number.isRequired,
        url: PropTypes.string.isRequired,
      })
    ).isRequired,
  }),
  /** The color variant of the tile that was chosen by the user. This is one of the color variant that is stored in the tile object.  */
  colorVariant: PropTypes.shape({
    code: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
  }),

  /** Dimensions of the wall on which the tiles are applied. */
  wall: PropTypes.instanceOf(Wall),
  /** The amount of tiles the user needs to buy to cover the wall */
  amountOfTilesToBuy: PropTypes.instanceOf(AmountOfTilesToBuy),

  /** URL of the render of the wall with it's windows, doors and tiles applied on it*/
  wallRenderedImageUrl: PropTypes.string.isRequired,
  /** Url of the technical plan image (the image showing only the outlines of tiles) of the wall */
  wallTechnicalPlanImageUrl: PropTypes.string.isRequired,
};

export default PdfUserChoices;
