import React, { useState } from "react";

// Helpers
import localizer, { getCurrentLanguage } from "localization/localizer";
import { sendUserInfoToCRM } from "api";
import { isProduction } from "helpers/env";
import { capitalize } from "helpers/StringHelper";

// Components
import PdfPage from "pages/PdfPage";
import ButtonFontAwesomeIcon from "components/ButtonFontAwesomeIcon";

// Styling
import styled from "styled-components";
import { AnimatePresence, motion } from "framer-motion";
import { faTimes } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

// Constants

const artePrivacyPolicyUrl = "https://www.arte-international.com/";
const artePrivacyPolicyUrlPath = "/legal/privacy-policy";

// Styled components

const Layout = styled.div`
  position: absolute;

  overflow: visible;
`;

const DownloadModal = styled(motion.div)`
  position: absolute;
  bottom: 0;
  right: 0;

  background-color: white;
  display: flex;
  flex-flow: column;
  border-radius: 2px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  padding: 20px 30px;
  align-items: center;
`;

const DownloadModalTitle = styled.p`
  padding-right: 16px;
  margin: 0;
  margin-bottom: 20px;

  font-size: 24px;
  font-weight: 400;
  white-space: nowrap;
`;

const EmailInput = styled.input`
  font-size: 16px;
  width: 97%;
`;

const InvalidEmailMessage = styled.div`
  font-size: 14px;
  text-align: left;
  width: 100%;
  color: red;
  margin-top: 5px;
  margin-bottom: 10px;
`;

const HorizontalWrapper = styled.div`
  display: flex;
  flex-flow: row;
  margin-bottom: 10px;
  justify-content: space-between;
`;

const NameInput = styled.input`
  font-size: 1rem;
  width: 45%;
`;

const LastNameInput = styled.input`
  font-size: 1rem;
  width: 45%;
`;

const DownloadButton = styled.button.attrs((props) => ({
  disabled: !props.enabled,
  type: "button",
}))`
  background-color: ${(props) =>
    props.enabled ? "rgba(0, 0, 0, 1)" : "rgba(0, 0, 0, 0.2)"};
  width: 100%;

  font-size: 1rem;
  text-align: center;
  color: white;

  padding: 5px 0;

  cursor: ${(props) => (props.enabled ? "pointer" : "default")};
`;

const GdprWrapper = styled.div`
  display: flex;
  flex-flow: row;
  width: 100%;
  margin-bottom: 10px;
`;

const GdprCheckbox = styled.input`
  margin-right: 8px;
`;

const GdprMessage = styled.div``;

const LinkToNewTab = styled.a.attrs((props) => ({
  target: "_blank",
  rel: "noreferrer noopener", // Added this based on the MDN recommendation: https://developer.mozilla.org/en-US/docs/Web/HTML/Element/a#attributes (see the "target" attribute)
}))`
  color: currentColor;
`;

const DownloadResultButtonPosition = styled(motion.div)`
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;
`;

const DownloadResultsButton = styled.button`
  padding: 0.5rem 1rem;

  font-family: inherit;
  font-size: 1.5rem;
  color: white;

  border: none;
  border-radius: 3px;
  background-color: #525252;

  cursor: pointer;
`;

const CollapseModalButton = styled(ButtonFontAwesomeIcon)`
  position: absolute;
  top: 4px;
  right: 4px;

  width: 24px;
  height: 24px;
`;

// Animations

const buttonToExpandModalVariants = {
  hidden: {
    opacity: 0,
    transition: { ease: "linear", duration: 0.05 },
  },
  shown: {
    opacity: 1,
    transition: { ease: "easeIn", duration: 0.25 },
  },
};

const modalVariants = {
  hidden: {
    y: "5%",
    opacity: 0,
    transition: { ease: "easeIn", duration: 0.25 },
  },
  shown: { y: 0, opacity: 1, transition: { ease: "easeOut" } },
};

/**
 * Form that allows the user to download a pdf with all the choices he made in the configuration.
 * The user must first enter valid informations before he is allowed to download the pdf.
 * Initially, the form will be hidden and can be shown by click on a button
 */
const DownloadResultsFormCollapsible = ({
  currentTile,
  currentPieceOfArt,
  currentColor,
  currentWall,
  render,
  amountOfTilesToBuy,
  wallTechnicalPlanImageUrl,
  className,
}) => {
  const [isModalCollapsed, setIsModalCollapsed] = useState(true);

  const [invalidEmail, setInvalidEmail] = useState(false);
  const [isEmpty, setIsEmpty] = useState(true);

  const [downloadPressed, setDownloadPressed] = useState(false);

  const privacyUrl =
    artePrivacyPolicyUrl + getCurrentLanguage() + artePrivacyPolicyUrlPath;

  const resetForm = () => {
    setInvalidEmail(false);
    setIsEmpty(true);
  };

  const onEmailChanged = (e) => {
    var pattern = new RegExp(
      /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
    );

    setInvalidEmail(!pattern.test(e.target.value));

    checkEmptyFields();
  };

  const onNameChanged = () => {
    checkEmptyFields();
  };

  const checkEmptyFields = () => {
    const nameField = document.getElementById("name");
    const lastNameField = document.getElementById("lastName");
    const emailField = document.getElementById("email");
    const gdprField = document.getElementById("gdpr");

    const empty =
      nameField.value.length === 0 ||
      lastNameField.value.length === 0 ||
      emailField.value.length === 0 ||
      !gdprField.checked;
    setIsEmpty(empty);
  };

  const uploadUserInfo = () => {
    const name = document.getElementById("name").value;
    const lastName = document.getElementById("lastName").value;
    const email = document.getElementById("email").value;

    if (isProduction()) {
      sendUserInfoToCRM({ name, lastName, email }, currentTile.name);
    }
  };

  const buttonToExpandModal = (
    <DownloadResultButtonPosition
      variants={buttonToExpandModalVariants}
      initial="hidden"
      animate="shown"
      exit="hidden"
    >
      <DownloadResultsButton onClick={() => setIsModalCollapsed(false)}>
        {capitalize(localizer.downloadResults)}
      </DownloadResultsButton>
    </DownloadResultButtonPosition>
  );

  const modal = (
    <DownloadModal
      variants={modalVariants}
      initial="hidden"
      animate="shown"
      exit="hidden"
    >
      <CollapseModalButton
        onClick={() => {
          setIsModalCollapsed(true);
          resetForm();
        }}
        icon={<FontAwesomeIcon icon={faTimes} size="xs" />}
      />
      <DownloadModalTitle>
        {capitalize(localizer.downloadResults)}
      </DownloadModalTitle>
      <HorizontalWrapper>
        <NameInput
          autoFocus
          id="name"
          type="text"
          autoComplete="given-name"
          placeholder={localizer.firstName}
          onChange={onNameChanged}
        />
        <LastNameInput
          id="lastName"
          type="text"
          autoComplete="family-name"
          placeholder={localizer.lastName}
          onChange={onNameChanged}
        />
      </HorizontalWrapper>
      <EmailInput
        id="email"
        type="email"
        autoComplete="email"
        placeholder={localizer.email}
        onChange={onEmailChanged}
      />
      <InvalidEmailMessage>
        {invalidEmail ? localizer.emailInvalidMessage : ""}
      </InvalidEmailMessage>
      <GdprWrapper>
        <GdprCheckbox type="checkbox" id="gdpr" onChange={checkEmptyFields} />
        <GdprMessage>
          {localizer.formatString(
            localizer.pdfDownloadForm.gdprSentence,
            <LinkToNewTab href={privacyUrl}>
              {localizer.pdfDownloadForm.gdprPrivacyPolicy}
            </LinkToNewTab>
          )}
        </GdprMessage>
      </GdprWrapper>
      <DownloadButton
        enabled={!invalidEmail && !isEmpty}
        onClick={() => {
          if (!invalidEmail && !isEmpty) {
            setDownloadPressed(true);
            uploadUserInfo();
          }
        }}
      >
        {downloadPressed ? localizer.downloading : localizer.download}
      </DownloadButton>
      {downloadPressed && render && (
        <PdfPage
          currentTile={currentTile}
          currentPieceOfArt={currentPieceOfArt}
          currentColor={currentColor}
          currentWall={currentWall}
          render={render}
          amountOfTilesToBuy={amountOfTilesToBuy}
          wallTechnicalPlanImageUrl={wallTechnicalPlanImageUrl}
          onDownload={() => {
            setDownloadPressed(false);
          }}
        />
      )}
    </DownloadModal>
  );

  return (
    <Layout className={className}>
      <AnimatePresence>
        {isModalCollapsed && buttonToExpandModal}
      </AnimatePresence>
      <AnimatePresence>{!isModalCollapsed && modal}</AnimatePresence>
    </Layout>
  );
};

export default DownloadResultsFormCollapsible;
