import React, { useEffect, useState, useRef } from "react";
import PropTypes from "prop-types";

// Classes
import Wall from "classes/Wall";
import AmountOfTilesToBuy from "classes/AmountOfTilesToBuy";

// Components
import DownloadPdfForm from "components/DownloadPdfForm";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/pro-light-svg-icons";

// Helpers
import localizer from "localization/localizer";
import {
  createNewCanvas,
  drawOnCanvas,
  drawFromCanvasToCanvas,
} from "helpers/CanvasHelper";
import {
  changeWallUnitOfLength,
  getTotalElementWidth,
} from "helpers/WallHelpers";
import { willCreateCanvasBlueprint } from "./VisualizerPageHelper";
import { getFurnitureForTileCode } from "data/FurnitureImagesRepository";

// Styling
import styled from "styled-components";
import UnitOfLengths from "classes/UnitOfLengths";

const MainWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-flow: column;
`;

const CenterCanvas = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  overflow-x: auto;
  overflow-y: hidden;
`;

const Canvas = styled.canvas`
  position: absolute;
  top: -100%;
  bottom: -100%;
  left: -100%;
  right: -100%;
  margin: auto;
`;

const LoadingIndicator = styled.div`
  display: flex;
  flex-flow: row;
  align-items: center;
`;

const Throbber = styled(FontAwesomeIcon)`
  font-size: 24px;
  margin-right: 15px;
`;

const LoadingText = styled.div`
  font-size: 24px;
  color: black;
`;

const StyledDownloadPdfForm = styled(DownloadPdfForm)`
  position: absolute;
  bottom: 35px;
  right: 25px;
`;

// Constants

// Choose which images to use
const wallImageDimensionsCm = { width: 900, height: 540 };

// Helper functions

/**
 *
 * @param {HTMLCanvasElement} canvas
 * @param {(ImageToDrawOnCanvas | ImageToDrawOnCanvas[])[]} imagesToDraw images that should be drawn on the canvas.
 *                                                                       The order of the images in the array matters as that is the order they will draw in
 *                                                                       You can also pass a nested array of images to have these images drawn together and then applied to the final canvas
 *                                                                       For example, if you pass "[img1, [img2_1, img2_2], img3]" then: "img1" gets drawn, "img2_1" and "img2_2" get drawn together,
 *                                                                       meaning that img2_2 will only affect img2_1 when getting drawn. Finally, img3 gets drawn on top of everything.
 * @returns {ImageToDrawOnCanvas} first image that was drawn on the canvas, this can be useful if you drew an array of images on a canvas and you want to reuse the result to put on another canvas
 */
const drawImagesOnCanvas = (canvas, imagesToDraw) => {
  const canvasContext = canvas.getContext("2d");

  // Clear canvas
  canvasContext.clearRect(0, 0, canvas.width, canvas.height);

  let firstImageDrawn = null;
  for (let index = 0; index < imagesToDraw.length; index++) {
    const imageOrImagesToDraw = imagesToDraw[index];

    let imageDrawn = null;
    if (Array.isArray(imageOrImagesToDraw)) {
      // There are multiple images to draw in one entry of the array
      // This means these images should be drawn in a separate canvas and that canvas then applied on the final canvas we want to draw to
      const imagesToDraw = imageOrImagesToDraw;

      const temporaryCanvasContext = createNewCanvas(
        canvasContext.canvas
      ).getContext("2d");

      // Draw the images on a separate canvas
      // This is a recursive call to this function
      // This means you can have nested arrays of images
      imageDrawn = drawImagesOnCanvas(
        temporaryCanvasContext.canvas,
        imagesToDraw
      );

      // Transfer the result from the temporary canvas to the final canvas
      drawFromCanvasToCanvas(
        temporaryCanvasContext.canvas,
        canvasContext,
        imageDrawn
      );
    } else {
      const imageToDraw = imageOrImagesToDraw;
      drawOnCanvas(canvasContext, imageToDraw);
      imageDrawn = imageToDraw;
    }

    if (index === 0) {
      firstImageDrawn = imageDrawn;
    }
  }

  return firstImageDrawn;
};

const drawOneLineToast = (canvas) => {
  const ctx = canvas.getContext("2d");

  let textToDisplay = localizer.visualizerPageToast;
  // We add 20 px for some padding left and right of the text
  let textWidth = ctx.measureText(textToDisplay).width + 20;

  // We position the toast 10 px from the bottom
  const yPos = canvas.height - 10;

  ctx.fillStyle = "rgba(255,255,255, 0.5)";
  const backgroundHeight = 20;
  ctx.fillRect(
    (canvas.width - textWidth) / 2.0,
    yPos - (backgroundHeight / 2.0 + 5), //  We add 5 px for some padding above and under the text
    textWidth,
    backgroundHeight
  );

  ctx.fillStyle = "#000000";
  ctx.fillText(textToDisplay, canvas.width / 2.0, yPos);
};

const drawTwoLineToast = (canvas) => {
  const ctx = canvas.getContext("2d");

  const textLine1 = localizer.visualizerPageToastSplit2.line1;
  const textLine2 = localizer.visualizerPageToastSplit2.line2;

  // We add 20 px for some padding left and right of the text
  const textLine1Width = ctx.measureText(textLine1).width + 20;
  const textLine2Width = ctx.measureText(textLine2).width + 20;

  const biggestWidth =
    textLine1Width > textLine2Width ? textLine1Width : textLine2Width;

  let yPos = canvas.height - 20;

  const backgroundHeight = 15;
  ctx.fillStyle = "rgba(255,255,255, 0.5)";
  ctx.textAlign = "center";
  ctx.fillRect(
    (canvas.width - biggestWidth) / 2.0,
    yPos - (backgroundHeight / 2.0 + 5), //  We add 5 px for some padding above and under the text
    biggestWidth,
    backgroundHeight * 2
  );

  ctx.fillStyle = "#000000";
  ctx.fillText(textLine1, canvas.width / 2.0, yPos);

  yPos += 15;

  ctx.fillText(textLine2, canvas.width / 2.0, yPos);
};

const drawThreeLineToast = (canvas) => {
  const ctx = canvas.getContext("2d");
  ctx.font = "11px Europa";

  const textLine1 = localizer.visualizerPageToastSplit3.line1;
  const textLine2 = localizer.visualizerPageToastSplit3.line2;
  const textLine3 = localizer.visualizerPageToastSplit3.line3;

  // We add 20 px for some padding left and right of the text
  const textLine1Width = ctx.measureText(textLine1).width + 20;
  const textLine2Width = ctx.measureText(textLine2).width + 20;
  const textLine3Width = ctx.measureText(textLine3).width + 20;

  const biggestWidth =
    textLine1Width > textLine2Width
      ? textLine1Width > textLine3Width
        ? textLine1Width
        : textLine3Width
      : textLine2Width > textLine3Width
      ? textLine2Width
      : textLine3Width;

  let yPos = canvas.height - 35;

  const backgroundHeight = 15;
  ctx.fillStyle = "rgba(255,255,255, 0.5)";
  ctx.textAlign = "center";
  ctx.fillRect(
    (canvas.width - biggestWidth) / 2.0,
    yPos - (backgroundHeight / 2.0 + 5), //  We add 5 px for some padding above and under the text
    biggestWidth,
    backgroundHeight * 3
  );

  ctx.fillStyle = "#000000";
  ctx.fillText(textLine1, canvas.width / 2.0, yPos);

  yPos += 15;
  ctx.fillText(textLine2, canvas.width / 2.0, yPos);

  yPos += 15;
  ctx.fillText(textLine3, canvas.width / 2.0, yPos);
};

const drawToastOnCanvas = (canvas) => {
  const ctx = canvas.getContext("2d");
  ctx.globalCompositeOperation = "source-over";

  ctx.font = "12px Europa";
  ctx.textAlign = "center";

  let textToDisplay = localizer.visualizerPageToast;
  // We add 20 px for some padding left and right of the text
  let textWidth = ctx.measureText(textToDisplay).width + 20;

  if (canvas.width - textWidth < 0) {
    textToDisplay = localizer.visualizerPageToastSplit2.line1;
    const extraLineToDisplay = localizer.visualizerPageToastSplit2.line2;

    textWidth = ctx.measureText(textToDisplay).width + 20;
    const extraTextWidth = ctx.measureText(extraLineToDisplay).width + 20;

    if (textWidth < canvas.width && extraTextWidth < canvas.width) {
      drawTwoLineToast(canvas);
    } else {
      drawThreeLineToast(canvas);
    }
  } else {
    drawOneLineToast(canvas);
  }
};

/**
 * Draw the scene on the provide canvas and return the result as an image
 * @param {HTMLCanvasElement} canvas canvas to draw on
 * @param {ImageToDrawOnCanvas[]} imagesToDraw images that should be drawn on the canvas, the last images in the array will be draw on top
 * @returns {string} url to the image that was drawn on the canvas
 */
const drawScene = (canvas, imagesToDraw) => {
  drawImagesOnCanvas(canvas, imagesToDraw);
  drawToastOnCanvas(canvas);
  return canvas.toDataURL("image/png", 1.0);
};

const VisualizerPage = ({
  tilePickedByUser,
  tileColorPickedByUser,
  currentFillType,
  artPiecePickedByUser,
  wallDefinedByUser,
  amountOfTilesToBuy,
  wallTechnicalPlanImageUrl,
  wallImageOffset,
}) => {
  const [renderedImage, setRenderedImage] = useState(null);

  const [canvasSize, setCanvasSize] = useState();
  const [imagesToDraw, setImagesToDraw] = useState();

  const canvasWrapperRef = useRef();
  const canvasRef = useRef();

  useEffect(() => {
    if (canvasWrapperRef.current) {
      // Make sure the wall is in cm
      const wallCm = changeWallUnitOfLength(
        wallDefinedByUser,
        UnitOfLengths.centimeters
      );

      // Get the height of the canvas from the HTML element wrapping the canvas where the scene will be drawn rendered in the viewport
      const canvasHeightPx = canvasWrapperRef.current.offsetHeight;

      const backgroundUrl =
        currentFillType === "decorative"
          ? artPiecePickedByUser.choosenColor.url
          : tileColorPickedByUser.wallUrl;

      let imageDimensions;
      if (currentFillType === "decorative") {
        imageDimensions = {
          width: getTotalElementWidth(artPiecePickedByUser),
          height: artPiecePickedByUser.dimensions.height,
        };
      } else {
        imageDimensions = wallImageDimensionsCm;
      }

      const wallImage = {
        src: backgroundUrl,
        dimensionsCm: imageDimensions,
        topLeftOffsetPx: {
          x: wallImageOffset.xOffset,
          y: wallImageOffset.yOffset,
        },
        hasTransparentAreas: currentFillType === "decorative",
      };

      willCreateCanvasBlueprint(
        canvasHeightPx,
        wallCm,
        wallImage,
        getFurnitureForTileCode(tileColorPickedByUser.code)
      ).then((canvasBlueprint) => {
        setCanvasSize(canvasBlueprint.canvasDimensionsPx);
        setImagesToDraw(canvasBlueprint.imagesToDrawOnCanvas);
      });
    }
  }, [
    currentFillType,
    artPiecePickedByUser,
    wallDefinedByUser,
    tileColorPickedByUser,
    wallImageOffset,
  ]);

  useEffect(() => {
    const canvas = document.getElementById("canvas");
    if (imagesToDraw && imagesToDraw.length > 0 && canvas) {
      setRenderedImage(drawScene(canvas, imagesToDraw));
    }
  }, [imagesToDraw]);

  return (
    <MainWrapper>
      <CenterCanvas ref={canvasWrapperRef}>
        <Canvas
          id="canvas"
          width={`${canvasSize?.width}px`}
          height={`${canvasSize?.height}px`}
          ref={canvasRef}
        />
        {!renderedImage && (
          <LoadingIndicator>
            <Throbber icon={faSpinner} pulse size={"1x"} />
            <LoadingText>{localizer.loading}</LoadingText>
          </LoadingIndicator>
        )}
      </CenterCanvas>
      {canvasWrapperRef.current && canvasRef.current && (
        <StyledDownloadPdfForm
          currentTile={tilePickedByUser}
          currentPieceOfArt={artPiecePickedByUser}
          currentColor={tileColorPickedByUser}
          currentWall={wallDefinedByUser}
          render={renderedImage}
          amountOfTilesToBuy={amountOfTilesToBuy}
          wallTechnicalPlanImageUrl={wallTechnicalPlanImageUrl}
        />
      )}
    </MainWrapper>
  );
};

VisualizerPage.propTypes = {
  tilePickedByUser: PropTypes.object.isRequired,
  tileColorPickedByUser: PropTypes.shape({
    // Unique code of this exact tile with this exact color
    code: PropTypes.number.isRequired,
    // Image of the wall that will be displayed in the visualizer
    wallUrl: PropTypes.string.isRequired,
  }).isRequired,
  currentFillType: PropTypes.oneOf(["wallFilling", "decorative"]).isRequired,
  artPiecePickedByUser: PropTypes.object,
  wallDefinedByUser: PropTypes.instanceOf(Wall).isRequired,
  /** Amount of tiles the user has to buy, this information is needed for the generation of the downloadable pdf */
  amountOfTilesToBuy: PropTypes.instanceOf(AmountOfTilesToBuy).isRequired,
  wallTechnicalPlanImageUrl: PropTypes.string.isRequired,
};

export default VisualizerPage;
