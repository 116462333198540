import Router from "./navigation/Router";

// Helpers
import { setLanguage } from "localization/localizer";
import { fetchParamsFromURL } from "helpers/UrlHelper";
import MobileDisabledPage from "pages/MobileDisabledPage";

const canUserViewWebSite = () => {
  const isViewportTooSmall = window.matchMedia(
    "(max-width: 700px or max-height:500 )"
  );
  const usesFingerTouch = window.matchMedia("(pointer : coarse)");
  return !isViewportTooSmall.matches && !usesFingerTouch.matches;
};

function App() {
  // Get the settings passed through url
  const params = fetchParamsFromURL();

  // Check for the language
  const languageSpecifiedInParams = params["lang"];
  if (languageSpecifiedInParams) {
    setLanguage(languageSpecifiedInParams);
  }

  // Check if tile reference is passed
  const tileSpecifiedInParams = params["ref"] ?? null;

  let content;
  if (canUserViewWebSite()) {
    content = <Router tileReferenceId={tileSpecifiedInParams} />;
  } else {
    content = <MobileDisabledPage />;
  }

  return <>{content}</>;
}

export default App;
