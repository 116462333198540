import React from "react";

// Components
import IdealImage from "components/IdealImage";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircle } from "@fortawesome/free-solid-svg-icons";
import Icon from "resources/icons/download_icon.png";

// helpers
import { motion } from "framer-motion";
import localizer from "localization/localizer";

// Styling
import styled from "styled-components";

const MainWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-flow: row;
`;

const ColorWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  row-gap: 25px;
  width: 80%;
  height: 75%;
  flex-wrap: wrap;
`;

const ColorOptionWrapper = styled(motion.div)`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-basis: 33%;
`;

const ColorOption = styled.div`
  display: flex;
  flex-flow: column;
  align-items: center;
`;

const ColorPreviewWrapper = styled(motion.div)`
  ${(props) => (props.selected ? "border: 2px solid black;" : "")}
  width: auto;
  height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ColorPreview = styled(IdealImage)`
  cursor: pointer;
  height: 100%;
`;

const DownloadDescription = styled.div`
  margin-left: 7px;
`;

const ColorNameWrapper = styled.div`
  display: flex;
  flex-flow: row;
  align-items: center;
  margin-top: 15px;
`;

const ColorCode = styled.div`
  font-size: 18px;
`;

const StyledCircle = styled(FontAwesomeIcon)`
  font-size: 6px;
  margin-left: 7px;
  margin-right: 7px;
  opacity: 0.75;
`;

const ColorInfoWrapper = styled.div`
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
`;

const DownloadButton = styled.a`
  display: flex;
  align-items: center;
  opacity: 0.5;
  border: none;
  background: none;
  text-decoration: none;
  color: inherit;
  cursor: pointer;
`;

const DownloadIcon = styled.img`
  width: 35px;
`;

const ColoursPage = ({ currentTile, onColorSpecified }) => {
  const colorPreviews = currentTile.tileColorVariants.map((color, index) => {
    return (
      <ColorOptionWrapper
        key={index}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.75, delay: index * 0.3 }}
      >
        <ColorOption>
          <ColorPreviewWrapper
            whileHover={{ scale: 1.05 }}
            onClick={() => {
              onColorSpecified(color);
            }}
          >
            <ColorPreview src={color.url} height={"100%"} />
          </ColorPreviewWrapper>
          <ColorInfoWrapper>
            <ColorNameWrapper>
              <ColorCode>{color.code}</ColorCode>
              <StyledCircle icon={faCircle} size="sm" />
              <ColorCode>{color.name}</ColorCode>
            </ColorNameWrapper>
            <DownloadButton
              href={color.downloadUrl}
              download={color.downloadName}
            >
              <DownloadIcon src={Icon} />
              <DownloadDescription>{localizer.downloadPNG}</DownloadDescription>
            </DownloadButton>
          </ColorInfoWrapper>
        </ColorOption>
      </ColorOptionWrapper>
    );
  });

  return (
    <MainWrapper>
      <ColorWrapper>{colorPreviews}</ColorWrapper>
    </MainWrapper>
  );
};

export default ColoursPage;
