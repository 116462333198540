import React, { useState } from "react";

// Components
import IdealImage from "components/IdealImage";

// Helpers
import { motion } from "framer-motion";
import { tiles } from "helpers/TilesConfig";

// Styling
import styled from "styled-components";

const MainWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const OptionsWrapper = styled.div`
  display: flex;
  flex-flow: row;
  justify-content: space-evenly;
  width: 100%;
`;

const Option = styled(motion.div)`
  display: flex;
  flex-flow: column;
  align-items: center;
`;

const SmallTileWrapper = styled.div`
  height: 120px;
`;

const SmallTile = styled(IdealImage)``;

const ScenePreviewWrapper = styled(motion.div)`
  position: relative;
  cursor: pointer;
  width: 17vw;
  max-width: 300px;
  height: 60vh;
  max-height: 450px;
  overflow: hidden;
`;

const ScenePreview = styled.img`
  position: absolute;
  top: -100%;
  bottom: -100%;
  left: -100%;
  right: -100%;
  margin: auto;
  transition: width 1s ease, height 1s ease;
`;

const Name = styled.div`
  margin-top: 15px;
  font-size: 18px;
`;

const previewMotion = {
  rest: {
    scale: 1,
    transition: { duration: 0.1 },
  },
  hover: {
    scale: 1.02,
    transition: { duration: 0.1 },
  },
};

const TileShapePage = ({ onTileSpecified }) => {
  const [hoveredTile, setHoveredTile] = useState();

  const previews = tiles.map((option, index) => {
    return (
      <Option
        key={index}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.75, delay: index * 0.3 }}
      >
        <SmallTileWrapper>
          <SmallTile
            src={option.url}
            width={option.previewSize.width}
            height={option.previewSize.height}
          />
        </SmallTileWrapper>
        <ScenePreviewWrapper
          initial="rest"
          whileHover="hover"
          animate="rest"
          variants={previewMotion}
          onHoverStart={() => {
            setHoveredTile(option);
          }}
          onHoverEnd={() => {
            setHoveredTile(null);
          }}
          onClick={() => {
            onTileSpecified(option);
          }}
        >
          <ScenePreview
            src={option.scene}
            height={hoveredTile === option ? "105%" : "100%"}
          />
        </ScenePreviewWrapper>
        <Name>{option.name}</Name>
      </Option>
    );
  });

  return (
    <MainWrapper>
      <OptionsWrapper>{previews}</OptionsWrapper>
    </MainWrapper>
  );
};

export default TileShapePage;
