import ImageToDrawOnCanvas from "classes/ImageToDrawOnCanvas";
/**
 * Compute how to draw an image on the canvas by keeping it's width/height ratio and by using the real world dimensions to decide on the portion of the height of the canvas it is going to use
 * Real world dimensions mean it's a size in a unit of length used in the real world such as cm or m. It reprensents the size of the content of the image if it was placed in the real world.
 * @param {{width: number, height: number}} imageDimensionsCm real world dimensions of the image (in cm)
 * @param {number} pixelsPerCm the number of pixels used per Cm
 * @return {{dimensions:{width: number, height: number}}} fields required to draw on the image on the canvas
 */
export const howToDrawInCanvasHeight = (imageDimensionsCm, pixelsPerCm) => {
  const imageDrawHeightPx = imageDimensionsCm.height * pixelsPerCm;

  const imageRatio = imageDimensionsCm.width / imageDimensionsCm.height;
  return {
    dimensions: {
      width: imageDrawHeightPx * imageRatio,
      height: imageDrawHeightPx,
    },
  };
};

/**
 * @typedef {Object} HowToDrawImageOnCanvas
 * @property {string} src
 * @property {{width: number, height: number}} dimensionsPx
 * @property {{x: number, y: number}} topLeft
 * @property {string=} [globalCompositeOperation] how this image will be blended with the existing images drawn on the canvas.
 *                                                You can see all possible values here: https://developer.mozilla.org/en-US/docs/Web/API/CanvasRenderingContext2D/globalCompositeOperation
 * @property {number} [globalAlpha]
 */

/**
 * Create image elements that can be drawn on a canvas. This means that the image elements have to be loaded, which takes time and explains why this returns a promise.
 * @param {HowToDrawImageOnCanvas[]} howToDrawImages
 * @returns {Promise<ImageToDrawOnCanvas[]>} Promise that resolves to a list of images that can be drawn on a canvas
 */
export const willCreateImagesForCanvas = (howToDrawImages) => {
  const promisesToLoadImages = [];
  howToDrawImages.forEach((imageDescription) => {
    promisesToLoadImages.push(willCreateImageForCanvas(imageDescription));
  });
  return Promise.all(promisesToLoadImages);
};

/**
 *
 * @param {HowToDrawImageOnCanvas} howToDrawImage
 * @returns {Promise<ImageToDrawOnCanvas>} Promise that resolve to an image that can be drawn on a canvas
 */
export const willCreateImageForCanvas = (howToDrawImage) => {
  return new Promise((resolve) => {
    const createdImage = new Image();
    createdImage.src = howToDrawImage.src;
    createdImage.onload = () => {
      resolve(
        new ImageToDrawOnCanvas(
          createdImage,
          howToDrawImage.dimensionsPx,
          howToDrawImage.topLeft,
          howToDrawImage.globalCompositeOperation,
          howToDrawImage.globalAlpha
        )
      );
    };
  });
};

/**
 * @param {CanvasRenderingContext2D} canvasContext
 * @param {ImageToDrawOnCanvas} imageToDraw
 */
export const drawOnCanvas = (canvasContext, imageToDraw) => {
  canvasContext.globalCompositeOperation = imageToDraw.globalCompositeOperation;
  canvasContext.globalAlpha = imageToDraw.globalAlpha;
  canvasContext.drawImage(
    imageToDraw.image,
    imageToDraw.topLeft.x,
    imageToDraw.topLeft.y,
    imageToDraw.dimensionsPx.width,
    imageToDraw.dimensionsPx.height
  );
};

/**
 *
 * @param {CanvasRenderingContext2D} from
 * @param {CanvasRenderingContext2D} to
 * @param {{dimensionsPx: {width: number, height: number}, topLeft: {x: number, y: number}, globalAlpha: number, globalCompositeOperation: string}} howToDraw
 */
export const drawFromCanvasToCanvas = (from, to, howToDraw) => {
  to.globalAlpha = howToDraw.globalAlpha;
  to.globalCompositeOperation = howToDraw.globalCompositeOperation;

  to.drawImage(
    from,
    howToDraw.topLeft.x,
    howToDraw.topLeft.y,
    howToDraw.dimensionsPx.width,
    howToDraw.dimensionsPx.height,
    howToDraw.topLeft.x,
    howToDraw.topLeft.y,
    howToDraw.dimensionsPx.width,
    howToDraw.dimensionsPx.height
  );
};

/**
 *
 * @param {HTMLCanvasElement} fromOtherCanvas
 * @returns {HTMLCanvasElement}
 */
export const createNewCanvas = (fromOtherCanvas) => {
  const newCanvas = document.createElement("canvas");
  newCanvas.width = fromOtherCanvas.width;
  newCanvas.height = fromOtherCanvas.height;
  return newCanvas;
};

/**
 * Get the total amount of non transparent pixels inside the canvas
 * @param {HTMLCanvasElement} canvas The canvas for which we get the non transparent pixels
 */
export const getNonTransparentPixelAmountForCanvas = (canvas) => {
  const data = canvas
    .getContext("2d")
    .getImageData(0, 0, canvas.width, canvas.height).data;

  let nonTransparentAmount = 0;
  for (let i = 0; i < data.length; i += 4) {
    const a = data[i + 3]; // A value

    if (a > 0) {
      nonTransparentAmount++;
    }
  }

  return nonTransparentAmount;
};
