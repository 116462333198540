import React from "react";
import PropTypes from "prop-types";

// Classes
import Wall from "classes/Wall";
import AmountOfTilesToBuy from "classes/AmountOfTilesToBuy";

// Helper
import localizer from "localization/localizer";

// Styling
import styled from "@react-pdf/styled-components";
import { NormalText } from "./pdfCommonStyles";
import { formatNumberArteStyle } from "helpers/NumberFormattingHelper";

const CalculationLayout = styled.View`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: stretch;
`;

const ResultsSection = styled.View`
  width: 100%;
`;

const Total = styled(NormalText)`
  margin-bottom: 8px;
`;

// Helper function

/**
 *
 * @param {{wall: Wall, amountOfTilesToBuy: AmountOfTilesToBuy}}
 */
const PdfAmountOfTileCalculations = ({ wall, amountOfTilesToBuy, style }) => {
  return (
    <CalculationLayout style={style}>
      <ResultsSection>
        <Total>{`${localizer.formatString(
          localizer.wallDimensions,
          formatNumberArteStyle(wall.width, wall.unitOfLength),
          formatNumberArteStyle(wall.height, wall.unitOfLength)
        )}`}</Total>
        <NormalText>{`${localizer.formatString(
          localizer.youNeedXTiles,
          amountOfTilesToBuy.exactAmount
        )}`}</NormalText>
        <NormalText>{`${localizer.formatString(
          localizer.soldPer,
          amountOfTilesToBuy.amountOfTilesPerPackage
        )}`}</NormalText>
        <NormalText>{`${localizer.formatString(
          localizer.orderQuantity,
          amountOfTilesToBuy.orderAmount
        )}`}</NormalText>
      </ResultsSection>
    </CalculationLayout>
  );
};

PdfAmountOfTileCalculations.propTypes = {
  /** Wall on which the tiles are applied used to compute the amount of tiles required to cover it */
  wall: PropTypes.instanceOf(Wall).isRequired,
  /** Amound of tiles the user needs to buy to cover the wall */
  amountOfTilesToBuy: PropTypes.instanceOf(AmountOfTilesToBuy).isRequired,
};

export default PdfAmountOfTileCalculations;
