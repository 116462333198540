import { React, useState } from "react";
import PropTypes from "prop-types";

// Components
import CustomizeWallDimensions from "./CustomizeWallDimensions";
import WallShowcase from "components/WallShowcase";

// Helpers
import localizer from "localization/localizer";
import { convert } from "helpers/WallHelpers";

// Classes
import Wall from "classes/Wall";
import UnitOfLengths from "classes/UnitOfLengths";

// Styling

import styled from "styled-components";

const Page = styled.div`
  width: 100%;
  height: 100%;
  padding-top: 1.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const PageContent = styled.div`
  width: 50%;
  height: 100%;

  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: min-content min-content;
  grid-template-areas:
    "header"
    "main";
  row-gap: 0.5rem;
  align-items: center;
  justify-content: center;
`;

const Header = styled.div`
  grid-area: header;
  text-align: center;
  font-size: 1.5rem;
`;

const Description = styled.p`
  font-size: 1.15rem;
`;

const Main = styled.div`
  grid-area: main;
`;

// Helper functions

/**
 * Compute all the data required to display the customize wall dimesnions step component
 * @param {{width: Number, height: Number}} [pieceOfArt] piece of art that will be displayed on wall
 * @returns {{description: String, props: Object}}
 */
const customizeWallDimensionsFields = (pieceOfArt) => {
  const step = {};

  const localizerForStep = localizer.wallDimensionsPage.customizeDimensions;
  step.description = localizerForStep.description;

  let minimumWallDimensionsProps = null;
  if (pieceOfArt) {
    const pieceOfArtWidthInM = convert(
      pieceOfArt.dimensions.width,
      pieceOfArt.dimensions.unitOfLength,
      UnitOfLengths.meters
    );
    const pieceOfArtHeightInM = convert(
      pieceOfArt.dimensions.height,
      pieceOfArt.dimensions.unitOfLength,
      UnitOfLengths.meters
    );
    minimumWallDimensionsProps = {
      minimumWallDimensions: {
        width: pieceOfArtWidthInM,
        height: pieceOfArtHeightInM,
      },
      reasonForMinWallDimensions: localizerForStep.wallMustContainPieceOfArt,
    };
  }

  step.props = minimumWallDimensionsProps;

  return step;
};

const WallDimensionsPage = ({
  initialWallDimensions,
  openWallShowcaseByDefault,
  onWallSubmittedByUser,
  onWallDimensionsChanged,
  OnWallDimensionsConfirmed,
  currentTile,
  currentFillType,
  currentPieceOfArt,
  wallImageOffset,
}) => {
  const [currentStep, setStep] = useState(openWallShowcaseByDefault ? 1 : 0);
  const [wallDefinedByUser, setWallDefinedByUser] = useState(
    initialWallDimensions
  );

  // Pick the main content to display based on the current step
  let description = "";
  let mainContent = null;
  if (currentStep === 0) {
    const {
      description: localDescription,
      props,
    } = customizeWallDimensionsFields(currentPieceOfArt);

    description = localDescription;

    mainContent = (
      <CustomizeWallDimensions
        initialWallDimensions={wallDefinedByUser}
        {...props}
        onWallDimensionsChanged={(wall) => {
          if (onWallDimensionsChanged) {
            onWallDimensionsChanged(wall);
          }
        }}
        onWallConfirmedByUser={(wall) => {
          setWallDefinedByUser(wall);
          setStep(currentStep + 1);
          if (onWallSubmittedByUser) {
            onWallSubmittedByUser(wall);
          }
        }}
      />
    );
  } else if (currentStep === 1) {
    description = localizer.wallDimensionsPage.wallPresentation.description;
    mainContent = (
      <WallShowcase
        currentTile={currentTile}
        currentFillType={currentFillType}
        currentPieceOfArt={currentPieceOfArt ? currentPieceOfArt : null}
        wall={wallDefinedByUser}
        wallImageOffset={wallImageOffset}
        onNextClicked={(
          amountOfTilesToBuy,
          wallRenderImageUrl,
          imageOffset
        ) => {
          if (OnWallDimensionsConfirmed)
            OnWallDimensionsConfirmed(
              amountOfTilesToBuy,
              wallRenderImageUrl,
              imageOffset
            );
        }}
        onBackClicked={() => {
          setStep(currentStep - 1);
        }}
      />
    );
  }

  return (
    <Page>
      <PageContent>
        <Header>
          <Description>{description}</Description>
        </Header>
        <Main>{mainContent}</Main>
      </PageContent>
    </Page>
  );
};

WallDimensionsPage.propTypes = {
  /** Dimensions that should be filled in the page before the user changes anything */
  initialWallDimensions: PropTypes.instanceOf(Wall),
  /**
   * Function called when any of the fields in wall dimensions are changed or when or when doors or windows are added/removed
   * @param {Wall} wall the wall that is currently defined by the user
   */
  onWallDimensionsChanged: PropTypes.func,
  /**
   * Function called when the user has submitted the dimensions of a wall. This means the user is done editing the dimensions.
   * @param {Wall} wall Wall object containing all the dimensions chosen by the user
   */
  onWallSubmittedByUser: PropTypes.func.isRequired,
  /**
   * Function called when the user is finished with this page. No parameters passed.
   * @callback
   * @param {AmountOfTilesToBuy} amountOfTilesToBuy
   * @param {string} wallRenderImageUrl The URL of the image rendered from the wall covered in tiles
   */
  OnWallDimensionsConfirmed: PropTypes.func.isRequired,
  /**
   * The way the tiles will be placed on the wall. Either it will fill the wall or it is a single piece of art that is placed on the wall.
   */
  currentFillType: PropTypes.oneOf(["wallFilling", "decorative"]).isRequired,
  /**
   * The piece of art that should be placed on the wall. This will only be used valid if the 'currentFillType' is 'decorative'.
   */
  currentPieceOfArt: PropTypes.shape({
    url: PropTypes.string,
    width: PropTypes.number,
    height: PropTypes.number,
  }),
};

export default WallDimensionsPage;
