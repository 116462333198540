import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

// Helpers
import localizer from "localization/localizer";
import { formatNumberArteStyle } from "helpers/NumberFormattingHelper";
import { convert, getTotalElementWidth } from "helpers/WallHelpers";

// Components
import UnitOfLengths from "classes/UnitOfLengths";

// Styling
import styled from "styled-components";
import { motion } from "framer-motion";

const VerticalWrapper = styled.div`
  display: flex;
  flex-flow: column;
  align-items: center;
  width: 100%;
  height: 100%;
`;

const ElementWrapper = styled.div`
  display: flex;
  flex-flow: column;
  width: 50%;
  align-items: center;
  justify-content: center;
  padding-bottom: 50px;
`;

const HorizontalWrapper = styled.div`
  display: flex;
  flex-flow: row;
  flex-wrap: wrap;
  justify-content: space-evenly;
  align-items: center;
  width: 100%;
  height: 100%;
`;

const StyledCanvas = styled(motion.canvas)`
  width: 80%;
  cursor: pointer;
  border: 1px solid black;
`;

const Description = styled.div`
  display: flex;
  flex-flow: row;
  align-items: center;
  font-size: 18px;
  margin-top: 15px;
  cursor: pointer;
`;

const CompositionSize = styled(Description)`
  margin-top: 5px;
`;

const ModuleSizeSection = styled.div`
  display: flex;
  flex-flow: column;
`;

const Text = styled.div``;

/**
 * Page to pick the decorative elements, for now there are only 2 elements per tile
 */
const PickDecorativeElementPage = ({
  currentTile,
  currentColor,
  onElementChoosen,
}) => {
  const [hoveredOption, setHoveredOption] = useState();
  useEffect(() => {
    currentTile.decorativeElements.forEach((element, index) => {
      const colorVariant = element.colorVariants.find(
        (variant) => variant.code === currentColor.code
      );

      element.choosenColor = colorVariant;

      const imagePromise = new Promise((resolve) => {
        const image = new Image();
        image.src = colorVariant.url;

        image.onload = () => {
          resolve(image);
        };
      });

      imagePromise.then((image) => {
        const canvas = document.getElementById(`canvas${index}`);
        const canvasContext = canvas.getContext("2d");

        // We setup a fake wall to display the art pieces, 8m * 6m seemed to work fine visually
        const fakeWallWidth = 800;
        const fakeWallHeight = 600;

        // Calculate wall aspect ratio
        const aspectRatio = fakeWallWidth / fakeWallHeight;

        // Here we set the resolution of the canvas, 1000 & 2000 are just some values that seemed to display the content in reasonable resolution
        const canvasWidth = Math.min(aspectRatio * 1000, 2000);
        const canvasHeight = canvasWidth / aspectRatio;
        canvas.width = canvasWidth;
        canvas.height = canvasHeight;

        const scaleX = (getTotalElementWidth(element) / fakeWallWidth) * 1.5;
        const scaleY = (element.dimensions.height / fakeWallHeight) * 1.5;

        const scaleImage = hoveredOption === index ? 1.2 : 1.1;

        const imageWidth = canvasWidth * scaleX * scaleImage;
        const imageHeight = canvasHeight * scaleY * scaleImage;

        const x = (canvas.width - imageWidth) / 2.0;
        const y = (canvas.height - imageHeight) / 2.0;

        canvasContext.clearRect(0, 0, canvas.width, canvas.height);

        canvasContext.drawImage(image, x, y, imageWidth, imageHeight);
      });
    });
  }, [currentTile, currentColor, hoveredOption]);

  return (
    <VerticalWrapper>
      <HorizontalWrapper>
        {currentTile.decorativeElements.map((element, index) => {
          return (
            <ElementWrapper
              onClick={() => {
                onElementChoosen(element);
              }}
            >
              <StyledCanvas
                key={`canvas${index}`}
                id={`canvas${index}`}
                onHoverStart={() => {
                  setHoveredOption(index);
                }}
                onHoverEnd={() => {
                  setHoveredOption(null);
                }}
              />
              <ModuleSizeSection>
                <Description>
                  {localizer.formatString(
                    localizer.youNeedXTilesComposition,
                    element.amountOfTilesNeeded
                  )}
                </Description>
                <CompositionSize>
                  <Text>
                    {`${localizer.moduleWH} ${
                      element.dimensions.width
                    } x ${formatNumberArteStyle(
                      element.dimensions.height,
                      element.dimensions.unitOfLength
                    )} (${formatNumberArteStyle(
                      convert(
                        element.dimensions.width,
                        element.dimensions.unitOfLength,
                        UnitOfLengths.feet
                      ),
                      UnitOfLengths.feet
                    )} x ${formatNumberArteStyle(
                      convert(
                        element.dimensions.height,
                        element.dimensions.unitOfLength,
                        UnitOfLengths.feet
                      ),
                      UnitOfLengths.feet
                    )})`}
                  </Text>
                </CompositionSize>
              </ModuleSizeSection>
            </ElementWrapper>
          );
        })}
      </HorizontalWrapper>
    </VerticalWrapper>
  );
};

PickDecorativeElementPage.propTypes = {
  /** The tile that was picked by the user */
  currentTile: PropTypes.object.isRequired,
  /** The color that was picked by the user */
  currentColor: PropTypes.object.isRequired,
  /** The back button was clicked */
  onBack: PropTypes.func,
  /** An art piece/element has been choosen
   * @callback
   * @param {Object} element The art piece choosen with the picked color
   */
  onElementChoosen: PropTypes.func,
};

export default PickDecorativeElementPage;
