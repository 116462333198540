import React, { useEffect, useState } from "react";

// Components
import { PDFDownloadLink } from "@react-pdf/renderer";
import PdfUserChoices from "components/PdfUserChoices";

const PdfPage = ({
  currentTile,
  currentPieceOfArt,
  currentColor,
  currentWall,
  amountOfTilesToBuy,
  render,
  wallTechnicalPlanImageUrl,
  onDownload,
}) => {
  const [documentLoaded, setDocumentLoaded] = useState(false);

  useEffect(() => {
    if (documentLoaded) {
      const links = document.getElementsByClassName("downloadLink");
      if (links) {
        if (links[0]) {
          links[0].click();
          onDownload();
        }
      }
    }
  }, [documentLoaded, onDownload]);

  return (
    <>
      <PDFDownloadLink
        className={"downloadLink"}
        document={
          <PdfUserChoices
            tile={currentTile}
            currentPieceOfArt={currentPieceOfArt}
            colorVariant={currentColor}
            wall={currentWall}
            amountOfTilesToBuy={amountOfTilesToBuy}
            wallTechnicalPlanImageUrl={wallTechnicalPlanImageUrl}
            wallRenderedImageUrl={render}
          />
        }
        fileName="arteconfigurator.pdf"
      >
        {({ blob, url, loading, error }) =>
          loading ? "" : setDocumentLoaded(true)
        }
      </PDFDownloadLink>
    </>
  );
};

export default PdfPage;
