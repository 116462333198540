import React, { useState } from "react";
import PropTypes from "prop-types";

// Components
import { Button } from "../components/commonStyledComponents";
import PickDecorativeElementPage from "./PickDecorativeElementPage";

// Helpers
import localizer from "localization/localizer";
import { motion } from "framer-motion";

// Styling
import styled from "styled-components";

const MainWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow-x: hidden;
`;

const HorizontalImageWrapper = styled.div`
  display: flex;
  flex-flow: row;
  justify-content: space-evenly;
  align-items: center;
  width: 100%;
  height: 100%;
`;

const ImageWrapper = styled(motion.div)`
  position: relative;
  overflow: hidden;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;

const Image = styled.img`
  position: absolute;
  top: -100%;
  bottom: -100%;
  left: -100%;
  right: -100%;
  margin: auto;
  transition: width 1s ease, height 1s ease;
`;

const StyledSubmitButton = styled(Button)`
  margin-top: 10px;
`;

const VerticalContainer = styled.div`
  display: flex;
  flex-flow: column;
  width: 45%;
  height: 85%;
`;

const FillingDecorativePage = ({
  currentTile,
  currentColor,
  currentFillType,
  onSubmitFillType,
}) => {
  const [isPickingDecorative, setIsPickingDecorative] = useState(false);

  const [hoveredOption, setHoveredOption] = useState();

  let content = null;

  if (isPickingDecorative) {
    content = (
      <PickDecorativeElementPage
        currentTile={currentTile}
        currentColor={currentColor}
        onBack={() => {
          setIsPickingDecorative(false);
        }}
        onElementChoosen={(element) => {
          onSubmitFillType("decorative", element);
        }}
      />
    );
  } else {
    content = (
      <HorizontalImageWrapper>
        <VerticalContainer>
          <ImageWrapper
            onHoverStart={() => {
              setHoveredOption("wallFilling");
            }}
            onHoverEnd={() => {
              setHoveredOption(null);
            }}
            onClick={() => {
              onSubmitFillType("wallFilling");
              setHoveredOption(null);
            }}
          >
            <Image
              src={currentColor.wallFillingRenderUrl}
              height={hoveredOption === "wallFilling" ? "105%" : "100%"}
              draggable="false"
            />
          </ImageWrapper>
          <StyledSubmitButton
            onClick={() => {
              onSubmitFillType("wallFilling");
            }}
          >
            {localizer.wallFilling}
          </StyledSubmitButton>
        </VerticalContainer>
        <VerticalContainer>
          <ImageWrapper
            onHoverStart={() => {
              setHoveredOption("decorative");
            }}
            onHoverEnd={() => {
              setHoveredOption(null);
            }}
            onClick={() => {
              setIsPickingDecorative(true);
              setHoveredOption(null);
            }}
          >
            <Image
              src={currentColor.wallDecorativeRenderUrl}
              height={hoveredOption === "decorative" ? "105%" : "100%"}
              draggable="false"
            />
          </ImageWrapper>
          <StyledSubmitButton
            onClick={() => {
              setIsPickingDecorative(true);
            }}
          >
            {localizer.decorative}
          </StyledSubmitButton>
        </VerticalContainer>
      </HorizontalImageWrapper>
    );
  }

  return <MainWrapper>{content}</MainWrapper>;
};

FillingDecorativePage.propTypes = {
  /**
   * When a fill type has already been decided, it should be passed in this prop.
   */
  currentFillType: PropTypes.string,
  /**
   * Function called when the fill type has been submitted by the user. This means he has decided both the fill type and, if needed, the piece of art that will be used.
   * @param {('wallFilling' | 'decorative')} fillType The way the wall should be filled. 'wallFilling' means the wall is covered in tiles and 'decorative' means a single "piece of art" is placed on the wall
   * @param {{name: String, url: String, width: Number, height: Number}} pieceOfArt The piece of art that should be placed on the wall, will only be set if 'fillType' is 'decorative'
   */
  onSubmitFillType: PropTypes.func.isRequired,
};

export default FillingDecorativePage;
