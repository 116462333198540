import axios from "axios";

import { collectionCode } from "../helpers/TilesConfig";

const crmUrl = "https://arte.azure-api.net/dyn/Simulation";
const crmHeaderKey = "b85643acd91b47f1985ff675428de34b";

/**
 * Send the user information to the crm system of Arte
 * @param {Object} user Main user object containing all the user information
 * @param {string} user.name The name of the user
 * @param {string} user.lastName The last name of the user
 * @param {string} user.email The email of the user
 * @param {('Camber' | 'Cubic' | 'Helix' | 'Prisma')} tileShape tileShape The tile shape choosen by the user, can be Camber, Cubic, Helix or Prisma
 */
export const sendUserInfoToCRM = (user, tileShape) => {
  if (!tileShape) {
    return Promise.reject(new Error("There was no tile shape passed"));
  }

  if (!user) {
    return Promise.reject(new Error("There was no user object passed"));
  }

  if (!user.name) {
    return Promise.reject(new Error("The user object does not contain a name"));
  }

  if (!user.lastName) {
    return Promise.reject(
      new Error("The user object does not contain a last name")
    );
  }

  if (!user.email) {
    return Promise.reject(
      new Error("The user object does not contain an email")
    );
  }

  const currentDate = new Date(Date.now());

  return axios.post(
    crmUrl,
    {
      Identity: {
        Origin: "Configurator",
        FirstName: user.name,
        LastName: user.lastName,
        Email: user.email,
      },
      Date: currentDate.toISOString(),
      Collection: collectionCode,
      Dessin: tileShape,
    },
    {
      headers: {
        "Ocp-Apim-Subscription-Key": crmHeaderKey,
      },
    }
  );
};
