import React, { useCallback, useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";

// Components
import { Button } from "./commonStyledComponents";

// Helpers
import Wall from "classes/Wall";
import localizer from "localization/localizer";
import {
  getDoorDistanceFromLeft,
  getWindowDistanceFromLeft,
  roundUp,
  changeWallUnitOfLength,
  getTotalElementWidth,
} from "helpers/WallHelpers";
import { getNonTransparentPixelAmountForCanvas } from "helpers/CanvasHelper";
import { formatNumberArteStyle } from "helpers/NumberFormattingHelper";

// Classes
import UnitOfLengths from "classes/UnitOfLengths";
import AmountOfTilesToBuy from "classes/AmountOfTilesToBuy";

// Styling
import styled from "styled-components";

const MainWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-flow: column;
`;

const CanvasWrapper = styled.div`
  position: relative;
  width: ${(props) => props.width}px;
  height: ${(props) => props.height}px;
  margin-top: -2.5rem;
`;

const CenterCanvas = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
`;

const Canvas = styled.canvas`
  border: 2px solid black;
`;

const WidthIndicator = styled.div`
  position: absolute;
  top: ${(props) => props.topOffset}px;
  left: ${(props) => props.leftOffset}px;
  // Center horizontally and vertically based on the width/height of the div
  transform: translate(-50%, 0);
`;

const HeightIndicator = styled.div`
  position: absolute;
  top: ${(props) => props.topOffset}px;
  left: ${(props) => props.leftOffset}px;
  // Center horizontally and vertically based on the width/height of the div
  transform: translate(-50%, 50%);
`;

const ButtonWrapper = styled.div`
  display: flex;
  flex-flow: row;
  width: 100%;
  justify-content: space-between;
`;

const BackButton = styled(Button)`
  justify-self: start;
  font-size: 1.2rem;
`;

const NextButton = styled(Button)`
  justify-self: end;
  font-size: 1.2rem;
`;

const TilesUsedSection = styled.div`
  display: flex;
  flex-flow: column;
  justify-self: start;
  position: relative;
  top: -30px;
`;

const NetTileAmountIndicator = styled.div`
  margin-top: 15px;
`;

const SoldPerIndicator = styled.div``;

const ExtraDescription = styled.div`
  margin-top: 10px;
`;

// Helper functions

/**
 * @returns {HTMLCanvasElement} canvas on which the wall is rendered
 */
const getWallRenderCanvas = () => {
  return document.getElementById("canvas");
};

const WallShowcase = ({
  wall,
  onBackClicked,
  onNextClicked,
  currentTile,
  currentFillType,
  currentPieceOfArt,
  wallImageOffset,
}) => {
  const [wallInCentimeters, setWallInCentimeters] = useState();

  const [isInDecorationMode, setIsInDecorationMode] = useState(false);

  const [backgroundToDraw, setBackgroundToDraw] = useState();
  const [backgroundSize, setBackgroundSize] = useState();

  const [canvasSize, setCanvasSize] = useState();
  const [pixelConversion, setPixelConversion] = useState();

  const [artPieceMeasurements, setArtPieceMeasurements] = useState();

  const [totalTileAmount, setTotalTileAmount] = useState();

  // Controls for dragging the background
  let currentX = useRef(0);
  let currentY = useRef(0);
  let initialX = useRef(0);
  let initialY = useRef(0);
  let xOffset = useRef(0);
  let yOffset = useRef(0);
  let isDragging = useRef(false);

  const drawRect = (ctx, x, y, width, height) => {
    ctx.beginPath();
    ctx.rect(x, y, width, height);
    ctx.fillStyle = "white";
    ctx.fill();
    ctx.lineWidth = 2;
    ctx.strokeStyle = "black";
    ctx.closePath();
    ctx.stroke();
  };

  // Calculate the tile amount needed after dragging a decorative element, this is not used for an entire wall
  const calculateNewTileAmountAfterDrag = useCallback(() => {
    const canvas = document.getElementById("canvas");

    // Get total amount of non transparent pixels
    let nonTransparentPixels = getNonTransparentPixelAmountForCanvas(canvas);

    // The doors are considered to be non transparent, we want to ignore these
    wallInCentimeters.doors.forEach((door) => {
      const pixelWidth = door.width * pixelConversion;
      const pixelHeight = door.height * pixelConversion;
      nonTransparentPixels -= pixelWidth * pixelHeight;
    });

    // The windows are considered to be non transparent, we want to ignore these as well
    wallInCentimeters.windows.forEach((window) => {
      const pixelWidth = window.width * pixelConversion;
      const pixelHeight = window.height * pixelConversion;
      nonTransparentPixels -= pixelWidth * pixelHeight;
    });

    // Get the percentage of the current non transparent pixels compared to the total amount of pixels for the art piece
    const percentageOfPixelsShown =
      nonTransparentPixels / artPieceMeasurements.nonTransparentPixels;

    // Finally calculate the new tile amount
    const newTileAmount =
      percentageOfPixelsShown * artPieceMeasurements.tileAmount;

    setTotalTileAmount(
      Math.min(roundUp(newTileAmount, 1), artPieceMeasurements.tileAmount)
    );
  }, [pixelConversion, artPieceMeasurements, wallInCentimeters]);

  const drawDoors = useCallback(
    (ctx) => {
      // Draw doors
      wallInCentimeters.doors.forEach((door) => {
        const doorWidth = door.width;
        const doorHeight = door.height;
        const doorPosFromLeft = getDoorDistanceFromLeft(
          door,
          wallInCentimeters
        );

        const posX = doorPosFromLeft * pixelConversion;
        const posY = canvasSize.height - doorHeight * pixelConversion;

        const pixelWidth = doorWidth * pixelConversion;
        const pixelHeight = doorHeight * pixelConversion;

        // We add 2 to the vertical position so the bottom border of the door aligns with the border of the wall
        drawRect(ctx, posX, posY + 2, pixelWidth, pixelHeight);
      });
    },
    [canvasSize, pixelConversion, wallInCentimeters]
  );

  const drawWindows = useCallback(
    (ctx) => {
      // Draw windows
      wallInCentimeters.windows.forEach((window) => {
        const windowWidth = window.width;
        const windowHeight = window.height;
        const windowPosFromLeft = getWindowDistanceFromLeft(
          window,
          wallInCentimeters
        );
        const windowPosFromGround = window.distanceFromFloor;

        const posX = windowPosFromLeft * pixelConversion;
        const posY =
          canvasSize.height -
          windowHeight * pixelConversion -
          windowPosFromGround * pixelConversion;

        const pixelWidth = windowWidth * pixelConversion;
        const pixelHeight = windowHeight * pixelConversion;

        drawRect(ctx, posX, posY, pixelWidth, pixelHeight);
      });
    },
    [canvasSize, pixelConversion, wallInCentimeters]
  );

  const draw = useCallback(
    (x, y) => {
      const canvas = document.getElementById("canvas");
      const ctx = canvas.getContext("2d");

      // Clear canvas
      ctx.clearRect(0, 0, ctx.canvas.width, ctx.canvas.height);

      // Draw background at given location
      ctx.drawImage(
        backgroundToDraw,
        x,
        y,
        backgroundSize.width,
        backgroundSize.height
      );

      drawDoors(ctx);
      drawWindows(ctx);

      if (isInDecorationMode) {
        // After drawing everything we update to see how much of the decorative element is displayed
        calculateNewTileAmountAfterDrag();
      }
    },
    [
      backgroundToDraw,
      backgroundSize,
      drawDoors,
      drawWindows,
      calculateNewTileAmountAfterDrag,
      isInDecorationMode,
    ]
  );

  const onMouseDown = useCallback((e) => {
    initialX.current = e.clientX - xOffset.current;
    initialY.current = e.clientY - yOffset.current;
    isDragging.current = true;
  }, []);

  const onMouseMove = useCallback(
    (e) => {
      if (isDragging.current) {
        currentX.current = e.clientX - initialX.current;
        currentY.current = e.clientY - initialY.current;

        if (isInDecorationMode) {
          const xLimit = backgroundSize.width;
          const yLimit = backgroundSize.height;

          // We want to keep a part of the element visible so the user doesn't lose it in the void
          const pixelsToRemainVisible = 50;

          if (
            currentX.current > -xLimit + pixelsToRemainVisible &&
            currentX.current < canvasSize.width - pixelsToRemainVisible &&
            currentY.current > -yLimit + pixelsToRemainVisible &&
            currentY.current < canvasSize.height - pixelsToRemainVisible
          ) {
            xOffset.current = currentX.current;
            yOffset.current = currentY.current;
            draw(currentX.current, currentY.current);
          } else if (
            currentX.current > -xLimit + pixelsToRemainVisible &&
            currentX.current < canvasSize.width - pixelsToRemainVisible
          ) {
            xOffset.current = currentX.current;
            draw(currentX.current, yOffset.current);
          } else if (
            currentY.current > -yLimit + pixelsToRemainVisible &&
            currentY.current < canvasSize.height - pixelsToRemainVisible
          ) {
            yOffset.current = currentY.current;
            draw(xOffset.current, currentY.current);
          }
        } else {
          const xLimit = backgroundSize.width - canvasSize.width;
          const yLimit = backgroundSize.height - canvasSize.height;
          if (
            currentX.current <= 0 &&
            currentY.current <= 0 &&
            currentX.current >= -xLimit &&
            currentY.current >= -yLimit
          ) {
            xOffset.current = currentX.current;
            yOffset.current = currentY.current;
            draw(currentX.current, currentY.current);
          } else if (currentX.current <= 0 && currentX.current >= -xLimit) {
            xOffset.current = currentX.current;
            draw(currentX.current, yOffset.current);
          } else if (currentY.current <= 0 && currentY.current >= -yLimit) {
            yOffset.current = currentY.current;
            draw(xOffset.current, currentY.current);
          }
        }
      }
    },
    [backgroundSize, canvasSize, isInDecorationMode, draw]
  );

  const onMouseUp = useCallback(
    (e) => {
      initialX.current = currentX.current;
      initialY.current = currentY.current;

      isDragging.current = false;

      if (isInDecorationMode) {
        calculateNewTileAmountAfterDrag();
      }
    },
    [isInDecorationMode, calculateNewTileAmountAfterDrag]
  );

  useEffect(() => {
    if (backgroundToDraw && backgroundSize && canvasSize) {
      setTimeout(() => {
        let x = 0;
        let y = 0;
        if (wallImageOffset) {
          x = wallImageOffset.xOffset * pixelConversion;
          y = wallImageOffset.yOffset * pixelConversion;
          xOffset.current = x;
          yOffset.current = y;
          initialX.current = x;
          initialY.current = y;
        }

        draw(x, y);
      }, 100);
    }
  }, [
    backgroundToDraw,
    backgroundSize,
    canvasSize,
    wallImageOffset,
    pixelConversion,
    draw,
  ]);

  useEffect(() => {
    if (canvasSize) {
      const canvas = document.getElementById("canvas");

      // Clone the element to remove all the event listeners
      let newClone = canvas.cloneNode();
      newClone.width = canvasSize.width;
      newClone.height = canvasSize.height;

      newClone.addEventListener("mousedown", onMouseDown, false);
      newClone.addEventListener("mouseup", onMouseUp, false);
      newClone.addEventListener("mousemove", onMouseMove, false);

      document.getElementById("parent").replaceChild(newClone, canvas);
    }
  }, [canvasSize, onMouseDown, onMouseUp, onMouseMove]);

  useEffect(() => {
    const wallInCm = changeWallUnitOfLength(wall, UnitOfLengths.centimeters);
    setWallInCentimeters(wallInCm);

    const inDecorationMode = currentFillType === "decorative";
    setIsInDecorationMode(inDecorationMode);

    if (!inDecorationMode) {
      let tileDimensions;
      tileDimensions = currentTile.tileDimensions;
      // Get the wall in the same unit as the tile to calculate the amount
      const wallInMm = changeWallUnitOfLength(
        wall,
        tileDimensions.unitOfLength
      );

      const totalAmount =
        Math.ceil(wallInMm.height / tileDimensions.height) *
        Math.ceil(wallInMm.width / tileDimensions.width);
      setTotalTileAmount(Math.ceil(totalAmount));
    } else {
      setTotalTileAmount(currentPieceOfArt.amountOfTilesNeeded);
    }

    // Calculate wall aspect ratio
    const aspectRatio = wallInCm.width / wallInCm.height;

    // Canvas size (draw field) or wall size in pixels.
    // 500, and 1000 are just some numbers that result in a decent sized wall on the screen
    const canvasWidth = Math.min(aspectRatio * 500, 1000);
    const canvasHeight = canvasWidth / aspectRatio;
    setCanvasSize({ width: canvasWidth, height: canvasHeight });

    // Scale the background image.
    let scaleX;
    let scaleY;
    if (inDecorationMode) {
      scaleX = getTotalElementWidth(currentPieceOfArt) / wallInCm.width;
      scaleY = currentPieceOfArt.dimensions.height / wallInCm.height;
    } else {
      // 900 & 540 is the size of the wall image in cm this is the same for all the walls, so we see how much bigger or smaller the wall is than the image
      scaleX = 900 / wallInCm.width;
      scaleY = 540 / wallInCm.height;
    }

    setPixelConversion(canvasWidth / wallInCm.width);

    const imagePromise = new Promise((resolve) => {
      const background = new Image();
      if (!inDecorationMode) {
        background.src = currentTile.schematicWall;
      } else {
        background.src = currentPieceOfArt.schematic;
      }

      background.onload = () => {
        resolve(background);
      };
    });

    imagePromise.then((image) => {
      let backgroundWidth = canvasWidth * scaleX;
      let backgroundHeight = canvasHeight * scaleY;

      if (inDecorationMode) {
        // Logic to get the total amount of pixels for the art piece
        const canvas = document.createElement("canvas");
        canvas.width = backgroundWidth;
        canvas.height = backgroundHeight;

        // We draw the art piece on a hidden canvas to get the total amount of pixels when the piece is entirely in the canvas
        canvas
          .getContext("2d")
          .drawImage(image, 0, 0, backgroundWidth, backgroundHeight);

        const nonTransparentPixels =
          getNonTransparentPixelAmountForCanvas(canvas);
        setArtPieceMeasurements({
          tileAmount: currentPieceOfArt.amountOfTilesNeeded,
          nonTransparentPixels: nonTransparentPixels,
        });
      }

      setBackgroundSize({ width: backgroundWidth, height: backgroundHeight });
      setBackgroundToDraw(image);
    });
  }, [wall, currentPieceOfArt, currentTile, currentFillType]);

  const borderAroundCanvas = { x: 80, y: 60 }; // px
  let widthCanvasWithBorder = 0;
  let heightCanvasWithBorder = 0;

  if (canvasSize) {
    widthCanvasWithBorder = canvasSize.width + 2 * borderAroundCanvas.x;
    heightCanvasWithBorder = canvasSize.height + 2 * borderAroundCanvas.y;
  }

  const orderTileAmount = roundUp(totalTileAmount, currentTile.soldPer);

  return (
    <>
      {canvasSize && (
        <MainWrapper>
          <CanvasWrapper
            width={widthCanvasWithBorder}
            height={heightCanvasWithBorder}
          >
            <WidthIndicator
              topOffset={heightCanvasWithBorder - borderAroundCanvas.x / 2}
              leftOffset={widthCanvasWithBorder / 2}
            >
              {`${formatNumberArteStyle(wall.width, wall.unitOfLength)}`}
            </WidthIndicator>
            <HeightIndicator
              topOffset={heightCanvasWithBorder / 2}
              leftOffset={borderAroundCanvas.x / 2}
            >
              {`${formatNumberArteStyle(wall.height, wall.unitOfLength)}`}
            </HeightIndicator>
            <CenterCanvas id="parent">
              <Canvas
                id="canvas"
                width={`${canvasSize.width}px`}
                height={`${canvasSize.height}px`}
              />
            </CenterCanvas>
          </CanvasWrapper>
          <ButtonWrapper>
            <BackButton
              onClick={() => {
                if (onBackClicked) {
                  onBackClicked();
                }
              }}
            >
              {localizer.back}
            </BackButton>
            <NextButton
              onClick={() => {
                if (onNextClicked)
                  onNextClicked(
                    new AmountOfTilesToBuy(
                      totalTileAmount,
                      currentTile.soldPer,
                      orderTileAmount
                    ),
                    getWallRenderCanvas().toDataURL("image/png", 1.0),
                    {
                      xOffset: xOffset.current / pixelConversion,
                      yOffset: yOffset.current / pixelConversion,
                    }
                  );
              }}
            >
              {localizer.next}
            </NextButton>
          </ButtonWrapper>
          <TilesUsedSection>
            <NetTileAmountIndicator>
              {`${localizer.formatString(
                localizer.youNeedXTiles,
                totalTileAmount
              )}`}
            </NetTileAmountIndicator>
            <SoldPerIndicator>
              {`${localizer.formatString(
                localizer.soldPer,
                currentTile.soldPer
              )}`}
            </SoldPerIndicator>
            <SoldPerIndicator>
              {`${localizer.formatString(
                localizer.orderQuantity,
                roundUp(totalTileAmount, currentTile.soldPer)
              )}`}
            </SoldPerIndicator>
            {currentPieceOfArt?.extraDescription && (
              <ExtraDescription>
                {localizer[currentPieceOfArt.extraDescription]}
              </ExtraDescription>
            )}
          </TilesUsedSection>
        </MainWrapper>
      )}
    </>
  );
};

WallShowcase.propTypes = {
  /** Wall to be filled with tiles */
  wall: PropTypes.instanceOf(Wall).isRequired,
  /**
   * Callback when the user confirmed that he's fine with the wall dimensions as they are shown in the showcase
   * @callback
   * @param {AmountOfTilesToBuy} amountOfTilesToBuy the tiles the user has to buy
   * @param {string} wallImageUrl the url of the image of the wall with doors and windows
   * @param {Object} imageOffset the offset of the image in cm to the 0,0 top left origin of the canvas
   */
  onNextClicked: PropTypes.func,
  /** The back button was clicked */
  onBackClicked: PropTypes.func,
};

export default WallShowcase;
